import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import toast from 'react-hot-toast';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function SubmitProposal() {
  const { proposal_id } = useParams();
  const [link, setLink] = useState('');
  const [userUID, setUserUID] = useState(null);
  const [platform, setPlatform] = useState('');
  const [verificationError, setVerificationError] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const initialize = async () => {
      try {
        const { data: { user }, error: userError } = await supabase.auth.getUser();
        if (userError || !user) {
          toast.error('Please sign in to submit a video');
          navigate('/login');
          return;
        }
        setUserUID(user.id);

        const response = await fetch(`/server/api/proposals/${proposal_id}/platform`);
        if (!response.ok) {
          throw new Error('Failed to fetch proposal details');
        }
        const { platform } = await response.json();
        setPlatform(platform);

      } catch (error) {
        console.error('Error initializing:', error);
        toast.error('Failed to load proposal details');
      }
    };

    initialize();
  }, [proposal_id, navigate]);

  const isValidLink = (url) => {
    if (platform === 'YouTube') {
      return url.includes('youtube.com') || url.includes('youtu.be');
    }
    if (platform === 'TikTok') {
      return url.includes('tiktok.com');
    }
    return url.includes('youtube.com') || url.includes('youtu.be') || url.includes('tiktok.com');
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setVerificationError('');

    if (!userUID) {
      toast.error('Please sign in to submit your video');
      return;
    }

    if (!link) {
      toast.error('Please provide a video link');
      return;
    }

    if (!isValidLink(link)) {
      toast.error('Invalid video link format');
      return;
    }

    try {
      const verifyResponse = await fetch('/server/api/submissions/verify', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ link, userId: userUID })
      });

      if (!verifyResponse.ok) {
        const error = await verifyResponse.json();
        // Set the platform name based on the link
        const platformName = link.includes('youtube.com') || link.includes('youtu.be') 
          ? 'YouTube' 
          : 'TikTok';
        setVerificationError(platformName);
        throw new Error(error.error || 'Failed to verify video');
      }

      const { title } = await verifyResponse.json();

      const submitResponse = await fetch('/server/api/submissions/submit', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          link,
          title,
          proposalId: proposal_id,
          userId: userUID
        })
      });

      if (!submitResponse.ok) {
        const error = await submitResponse.json();
        throw new Error(error.error || 'Failed to submit video');
      }

      toast.success('Video submitted successfully!');
      navigate(`/expanded-proposal/${proposal_id}`);

    } catch (error) {
      console.error('Error submitting video:', error);
      // Don't show toast error here since we're showing the verification error in the UI
      if (!verificationError) {
        toast.error(error.message || 'Failed to submit video');
      }
    }
  };

  return (
    <div>
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg">
                Submit the link to your content to complete this proposal.
                If your video is selected, you will be rewarded via the payment method in your Profile.
              </p>
              <br />
              <p className="text-red-500">
                Must be posted on {platform === 'Any' ? 'YouTube or TikTok' : platform}.
              </p>
              {verificationError && (
                <p className="mt-4 text-red-500">
                  Make sure your {verificationError} account is connected in your{' '}
                  <a 
                    href="/profile" 
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="font-medium hover:text-red-700"
                  >
                    Profile
                  </a>.
                </p>
              )}

            </div>

            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form onSubmit={handleSubmit} className="space-y-4">
                <div>
                  <label htmlFor="link" className="sr-only">Link to your video</label>
                  <input
                    type="text"
                    id="link"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="Link to your video"
                    value={link}
                    onChange={(e) => setLink(e.target.value)}
                    required
                  />
                </div>
                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Submit Your Content!
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}