import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import toast from 'react-hot-toast';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJECT_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

export default function Onboard() {
  const [platform, setPlatform] = useState('');
  const [accountType, setAccountType] = useState('');
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [initialAccountType, setInitialAccountType] = useState(null);
  const [userUID, setUserUID] = useState(null);
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    async function fetchUser() {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        
        if (error || !user) {
          console.error('Error fetching user:', error?.message || 'No user session found');
          navigate('/login');
          return;
        }

        // console.log('Fetched user data:', user);
        setUserUID(user.id);
        setUserData({
          display_name: user.user_metadata?.full_name || user.user_metadata?.name,
          email: user.email,
        });

        await loadUserPreferences(user.id);
      } catch (error) {
        console.error('Error in fetchUser:', error);
        toast.error('Error fetching user data');
      }
    }

    async function loadUserPreferences(userId) {
      try {
        const { data, error } = await supabase
          .from('public_users')
          .select('account_type, preferred_platform')
          .eq('id', userId)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          console.error('Error fetching user preferences:', error.message);
          return;
        }

        if (data) {
          setInitialAccountType(data.account_type);
          setAccountType(data.account_type || '');
          setPlatform(data.preferred_platform || '');
        }
      } catch (error) {
        console.error('Error in loadUserPreferences:', error);
        toast.error('Error loading preferences');
      }
    }

    fetchUser();
  }, [navigate]);

  useEffect(() => {
    const handleBeforeUnload = (e) => {
      if (unsavedChanges) {
        e.preventDefault();
        e.returnValue = '';
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  }, [unsavedChanges]);

  const handleInputChange = (setter) => (e) => {
    setter(e.target.value);
    setUnsavedChanges(true);
  };

  async function handleSave(e) {
    e.preventDefault();
    if (!userUID || !userData) {
      toast.error('User data not available');
      return;
    }

    try {
      const { error: upsertError } = await supabase
        .from('public_users')
        .upsert({
          id: userUID,
          account_type: accountType,
          preferred_platform: platform,
          display_name: userData.display_name,
          email: userData.email,
          proposal_votes: {},
          submission_votes: {},
          platforms: {
            tiktok: { connected: false, last_sync: null },
            youtube: { connected: false, last_sync: null }
          }
        });

      if (upsertError) throw upsertError;

      toast.success('Information saved successfully!');
      setUnsavedChanges(false);
      
      if (!initialAccountType) {
        navigate('/ideas');
      } else {
        navigate('/profile');
      }
    } catch (error) {
      console.error('Error saving information:', error.message);
      toast.error('Error saving information. Please try again.');
    }
  }

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-cols-1 gap-x-16 gap-y-8 lg:grid-cols-1">
            <div className="rounded-lg bg-white p-8 shadow-lg lg:p-12">
              <form onSubmit={handleSave} className="space-y-4">
                <p className="text-lg font-medium">Which best describes you?</p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  {['Creator', 'Viewer', 'Business'].map(type => (
                    <div key={type.toLowerCase()}>
                      <input
                        type="radio"
                        id={type.toLowerCase()}
                        className="peer sr-only"
                        name="accountType"
                        value={type}
                        checked={accountType === type}
                        onChange={handleInputChange(setAccountType)}
                        required
                      />
                      <label
                        htmlFor={type.toLowerCase()}
                        className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white"
                        tabIndex="0"
                      >
                        <span className="text-sm"> {type} </span>
                      </label>
                    </div>
                  ))}
                </div>

                <p>
                  {accountType === 'Creator'
                    ? "Select the platform(s) you want to post content for:"
                    : accountType === 'Business'
                    ? "Select the platform(s) you want to target users on:"
                    : "Select the platform(s) you spend the most time on:"}
                </p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  {['YouTube', 'TikTok', 'Any'].map(plat => (
                    <div key={plat.toLowerCase()}>
                      <input
                        type="radio"
                        id={plat.toLowerCase()}
                        className="peer sr-only"
                        name="platform"
                        value={plat}
                        checked={platform === plat}
                        onChange={handleInputChange(setPlatform)}
                        required
                      />
                      <label
                        htmlFor={plat.toLowerCase()}
                        className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white"
                        tabIndex="0"
                      >
                        <span className="text-sm"> {plat === 'Any' ? 'Both' : plat} </span>
                      </label>
                    </div>
                  ))}
                </div>

                <div className="mt-4">
                  <button
                    type="submit"
                    className="inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto"
                  >
                    Save
                  </button>
                </div>
                {unsavedChanges && (
                  <p className="text-red-500 mt-2">You have unsaved changes.</p>
                )}
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}