import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { toast } from 'react-hot-toast';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

const Contribution = () => {
  const { session_id } = useParams();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [proposal, setProposal] = useState(null);
  const [contribution, setContribution] = useState(null);
  const [winnerSelectionDeadline, setWinnerSelectionDeadline] = useState(null);
  const [selectedPayoutOption, setSelectedPayoutOption] = useState("customer_choice");
  const [error, setError] = useState(null);
  const [userUID, setUserUID] = useState(null);

  const payoutInfo = {
    customer_choice: {
      label: 'You choose the winner',
      description: 'You choose the single best submission. You can choose the winner for up to 7 days after the proposal expiration.',
    },
    peoples_choice: {
      label: 'Everyone chooses the winner',
      description: 'The most popular submission by likes will get your bounty.',
    }
  };

  useEffect(() => {
    const initializeUser = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        setUserUID(user?.id || null);
      } catch (error) {
        console.error('Error getting user:', error);
        setUserUID(null);
      }
    };

    initializeUser();
  }, []);

  useEffect(() => {
    const fetchContribution = async () => {
      try {
        setLoading(true);
        
        if (!session_id) {
          throw new Error('Missing session ID');
        }

        const response = await fetch(`/server/api/contributions/${session_id}`);
        if (!response.ok) {
          throw new Error('Failed to fetch contribution details');
        }

        const data = await response.json();
        
        setProposal(data.proposal);
        setContribution(data.contribution);
        setWinnerSelectionDeadline(data.winnerSelectionDeadline);
        setSelectedPayoutOption(data.transaction?.payout_preference || "customer_choice");

        // Record transaction if needed
        if (data.session) {
          await fetch(`/server/api/contributions/${session_id}/record`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              sessionData: data.session
            })
          });
        }

      } catch (error) {
        console.error('Error fetching contribution:', error);
        setError(error.message);
        toast.error(`Error: ${error.message}`);
      } finally {
        setLoading(false);
      }
    };

    fetchContribution();
  }, [session_id]);

  const handlePayoutOptionChange = async (option) => {
    try {
      if (!userUID) {
        toast.error('Please sign in to change payout method');
        return;
      }

      const response = await fetch(`/server/api/contributions/${session_id}/payout`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          payoutMethod: option.value,
          userId: userUID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update payout method');
      }

      setSelectedPayoutOption(option.value);
      toast.success('Payout method updated successfully');
    } catch (error) {
      console.error('Error updating payout method:', error);
      toast.error(error.message);
      setSelectedPayoutOption("customer_choice"); // Fallback
    }
  };

  if (loading) {
    return (
      <div className="bg-gray-100 py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-3xl text-gray-500">Loading contribution details...</p>
        </div>
      </div>
    );
  }
  if (error) {
    return (
      <div className="bg-gray-100 py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-3xl text-gray-500">Error: {error}</p>
          <Link to="/ideas" className="text-blue-600 hover:text-blue-800 hover:underline mt-4 inline-block">
            Return to Bounties
          </Link>
        </div>
      </div>
    );
  }

  if (!proposal || !contribution) {
    return (
      <div className="bg-gray-100 py-16">
        <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8 text-center">
          <p className="text-3xl text-gray-500">Contribution not found.</p>
          <Link to="/ideas" className="text-blue-600 hover:text-blue-800 hover:underline mt-4 inline-block">
            Find more Bounties
          </Link>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-gray-100 py-16">
      <div className="max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="bg-white rounded-lg shadow-lg p-8">
          {/* Title and Contribution Amount */}
          <h1 className="text-3xl font-bold mb-4">
            You contributed ${contribution.amount.toFixed(2)} to the proposal:{' '}
            <Link to={`/expanded-proposal/${proposal.id}`} className="text-blue-500 hover:underline">
              {proposal.title}
            </Link>
          </h1>

          {/* Transaction Details */}
          {contribution.status === 'complete' || contribution.status === 'paid' ? (
            <div className="mb-6">
              <div className="bg-green-50 border border-green-200 rounded-md p-4">
                <div className="flex">
                  <div className="flex-shrink-0">
                    <svg className="h-5 w-5 text-green-400" viewBox="0 0 20 20" fill="currentColor">
                      <path fillRule="evenodd" d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z" clipRule="evenodd" />
                    </svg>
                  </div>
                  <div className="ml-3">
                    <p className="text-sm font-medium text-green-800">
                      Payment successful!
                    </p>
                  </div>
                </div>
              </div>

              {/* Detailed Transaction Information */}
              <div className="mt-4 bg-gray-50 rounded-md p-4">
                <h3 className="text-lg font-semibold mb-2">Transaction Details:</h3>
                <div className="space-y-2 text-sm">
                <p>
                  <span className="font-medium">Date:</span>{' '}
                  {new Date(contribution.created).toLocaleString()}
                </p>
                  {contribution.customerDetails && (
                    <>
                      <p><span className="font-medium">Customer Name:</span> {contribution.customerDetails.name}</p>
                      <p><span className="font-medium">Email:</span> {contribution.customerDetails.email}</p>
                      {contribution.customerDetails.address && (
                        <p><span className="font-medium">Location:</span> {contribution.customerDetails.address.country}</p>
                      )}
                    </>
                  )}
                  <p><span className="font-medium">Payment Status:</span> {contribution.status}</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="mb-4 bg-yellow-50 border border-yellow-200 rounded-md p-4">
              <p className="text-sm text-yellow-800">
                Payment status: {contribution.status}
              </p>
            </div>
          )}

          {/* Payout Options Section */}
          <div className="mb-8">
            <h2 className="text-xl font-bold mb-2">Choose how your payment will be distributed</h2>
            <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-2">
              {Object.entries(payoutInfo).map(([payoutType, info]) => (
                <div key={payoutType}>
                  <input
                    type="radio"
                    id={`payout-${payoutType}`}
                    className="peer sr-only"
                    name="payout-option"
                    checked={selectedPayoutOption === payoutType}
                    onChange={() => handlePayoutOptionChange({ value: payoutType })}
                  />
                  <label
                    htmlFor={`payout-${payoutType}`}
                    className={`block w-full rounded-lg border border-gray-200 p-3 text-gray-600 
                      hover:border-black peer-checked:border-black peer-checked:bg-black 
                      peer-checked:text-white cursor-pointer transition-colors duration-200`}
                    tabIndex="0"
                  >
                    <span className="text-sm font-medium">
                      {info.label}
                    </span>
                    <div className={`mt-2 text-xs ${
                      selectedPayoutOption === payoutType ? 'text-gray-200' : 'text-gray-500'
                    }`}>
                      {info.description}
                    </div>
                  </label>
                </div>
              ))}
            </div>
          </div>

          {/* Footer Information */}
          <div className="text-center border-t pt-6 mt-6">
          <p className="text-gray-400 mb-2">
              If there are no valid submissions by {winnerSelectionDeadline ? new Date(winnerSelectionDeadline).toLocaleString() : 'the deadline'}, 
              the money will be returned to your account.
            </p>

            <p className="text-gray-400">
              Your payout preferences can be edited until the first submission.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Contribution;