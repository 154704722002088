import React, { useEffect, useState } from 'react';
import SubmitTextBox from '../components/SubmitTextBox.jsx';
import Proposal from '../components/Proposal.jsx';
import Submission from '../components/Submission.jsx';
import { Link, useParams } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import { differenceInDays, differenceInHours, differenceInMinutes, isPast } from 'date-fns';
import { toast } from 'react-hot-toast';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function ExpandedProposal() {
  const { proposal_id } = useParams();
  const [proposal, setProposal] = useState({});
  const [userUID, setUserUID] = useState(null);
  const [submissions, setSubmissions] = useState([]);
  const [totalBounty, setTotalBounty] = useState(0);

  const durationRanges = {
    SHORT: '< 3 minutes',
    MEDIUM: '5 - 25 minutes',
    LONG: '30+ minutes'
  };
  
  const formatDurations = (durationsString) => {
    if (!durationsString) return 'Video can be any length';
    
    const durations = durationsString.split(',');
    
    if (durations.length === 1) {
      return `Video should be ${durationRanges[durations[0]]} long`;
    }
    
    const lastDuration = durations[durations.length - 1];
    return `Video should be ${durationRanges[lastDuration]} long`;
  };

  const getTimeRemaining = (expirationDate) => {
    const now = new Date();
    const expiration = new Date(expirationDate);
    
    if (isPast(expiration)) {
      return 'Completed';
    }
    
    const daysRemaining = differenceInDays(expiration, now);
    if (daysRemaining > 0) {
      return `${daysRemaining} ${daysRemaining === 1 ? 'day' : 'days'}`;
    }
    
    const hoursRemaining = differenceInHours(expiration, now);
    if (hoursRemaining > 0) {
      return `${hoursRemaining} ${hoursRemaining === 1 ? 'hour' : 'hours'}`;
    }
    
    const minutesRemaining = differenceInMinutes(expiration, now);
    return `${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'}`;
  };

  async function getProposal() {
    const response = await fetch(`/server/api/proposals/${proposal_id}`);
    const data = await response.json();
    setProposal(data);
  }
  
  
async function getTotalBounty() {
  const response = await fetch(`/server/api/proposals/${proposal_id}/bounty`);
  const { total } = await response.json();
  setTotalBounty(total);
}

async function getAuthorDisplayName(userUID) {
  try {
    if (!userUID) {
      return 'Unknown';
    }

    const response = await fetch(`/server/api/users/${userUID}/display-name`);
    const data = await response.json();
    return data.display_name;

  } catch (error) {
    console.error('Error fetching author display name:', error.message);
    return 'Unknown';
  }
}

  
async function getSubmissions() {
  try {
    const response = await fetch(`/server/api/proposals/${proposal_id}/submissions`);
    const data = await response.json();
    
    // Ensure data is always an array
    setSubmissions(Array.isArray(data) ? data : []);
  } catch (error) {
    console.error('Error getting submissions:', error.message);
    setSubmissions([]); // Set empty array on error
  }
}

  useEffect(() => {
    getProposal();
    getTotalBounty();
  }, []);

  useEffect(() => {
    getSubmissions();
  }, []);

  useEffect(() => {
    async function getUserID() {
      const { data: { user }, error } = await supabase.auth.getUser();
      if (user) {
        setUserUID(user.id);
      }
    }
    getUserID();
  }, []);

  
async function handleVote(submissionId, vote) {
  if (!userUID) {
    console.log('Cannot submit vote, not signed in');
    alert('You must be logged in to vote.');
    return;
  }

  try {
    const response = await fetch(`/server/api/submissions/${submissionId}/vote`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ vote, userId: userUID })
    });

    const data = await response.json();
    
    if (!response.ok) throw new Error(data.error);

    // Update local state
    setSubmissions(prev => 
      prev.map(submission => 
        submission.id === submissionId 
          ? { ...submission, votes: data.newVoteTotal, userVote: data.userVote } 
          : submission
      )
    );

  } catch (error) {
    console.error('Error handling vote:', error);
    toast.error('Failed to update vote');
  }
}


  const handleContributeClick = async (id, amount, type = 'proposal') => {
    try {
      let userId = null;
  
      // Try to get user data using getUser
      try {
        const { data: { user }, error: authError } = await supabase.auth.getUser();
        if (authError) {
          console.error('Error fetching user:', authError.message);
        }
        userId = user?.id || null;
      } catch (userError) {
        console.log('No authenticated user:', userError);
        // Continue with null userId
      }
      
      const response = await fetch('/server/api/stripe/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: id,
          amount: Math.round(amount * 100),
          type: type,
          userId: userId  // Will be null if no user
        }),
      });
  
      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }
  
      const { url } = await response.json();
      if (url) {
        toast.promise(
          Promise.resolve(window.location.href = url),
          {
            loading: 'Preparing checkout...',
            success: 'Redirecting to secure payment',
            error: 'Could not initialize payment'
          }
        );
      }
    } catch (error) {
      console.error('Payment initiation error:', error);
      toast.error(`Payment failed: ${error.message}`);
    }
  };

  return (
    <div>
      <section>
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          <h1 className="text-4xl font-bold text-gray-800 mb-4">{proposal.title}</h1>
          <p style={{ whiteSpace: 'pre-wrap' }}>{proposal.description === "" ? "No description provided." : proposal.description}</p>
          <br />
          <div className="mb-6">
            <p className="font-semibold text-lg">Total Bounty: ${totalBounty.toFixed(2)}</p>
            {/* amount of money already allocated */}
            <p className="">You can earn up to: ${totalBounty.toFixed(2)}</p>
            <button
              onClick={() => handleContributeClick(proposal.id, 5.00)}
              className="mt-2 inline-block rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-900 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring"
            >
              Fund This Bounty
            </button>
          </div>
          <p className="font-semibold text-lg">Constraints:</p>
          {isPast(new Date(proposal.expiration)) ? (
            <p className="text-red-500 font-medium">
              This proposal is no longer accepting submissions.
            </p>
          ) : (
            <p className="font-medium">
              Video must be submitted in {getTimeRemaining(proposal.expiration)}.
            </p>
          )}
          <p className="text-red-500 font-semibold">
            Video must be posted on {proposal.platform === 'Any' ? 'YouTube or TikTok' : proposal.platform}.
          </p>
          <p>
            {formatDurations(proposal.duration)}.
          </p>

          {!isPast(new Date(proposal.expiration)) && (
            <div className="mt-4">
              <Link
                to={`/submit-content/${proposal.id}`} 
                className="inline-block rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-900 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring"
              >
                Submit Your Video
              </Link>
            </div>
          )}
          
          <h2 className="text-3xl font-bold text-gray-800 mt-8">Submissions</h2>
        
          {Array.isArray(submissions) && submissions.length === 0 ? (
            <div className="text-center mt-16">
              <p className="text-3xl text-gray-500">Be the first to submit!</p>
              <p className="text-gray-500 sm:text-xl">Early submissions are more likely to be rewarded.</p>
            </div>
          ) : (
            submissions.map((submission) => (
              <Submission
                key={submission.id}
                submission={submission}
                userUID={userUID}
                onVote={handleVote}
              />
            ))
          )}
        </div>
      </section>
    </div>
  );
}