import '../index.css';
import React, { useEffect, useState } from 'react';
import { createClient } from '@supabase/supabase-js';
import { Auth } from '@supabase/auth-ui-react';
import { ThemeSupa } from '@supabase/auth-ui-shared';
import { useNavigate } from 'react-router-dom';
import toast from 'react-hot-toast';

const supabase = createClient(
  process.env.REACT_APP_SUPABASE_PROJECT_URL,
  process.env.REACT_APP_SUPABASE_KEY
);

export default function Login() {
  const [session, setSession] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    let mounted = true;

    // Check if user exists with complete profile
    const checkUserProfile = async (userId) => {
      try {
        const { data, error } = await supabase
          .from('public_users')
          .select('id, account_type, preferred_platform, display_name, email')
          .eq('id', userId)
          .maybeSingle();

        if (error && error.code !== 'PGRST116') {
          throw error;
        }

        return data && data.account_type && data.preferred_platform;
      } catch (error) {
        console.error('Error checking user profile:', error);
        return false;
      }
    };

    // Create or update user in public_users table
    const upsertUser = async (user) => {
      try {
        if (!user) throw new Error('No user data provided');

        // Extract user identity data
        const identityData = user.identities?.[0]?.identity_data || {};
        
        // Prepare user data
        const userData = {
          id: user.id,
          email: user.email,
          display_name: identityData.full_name || 
                       identityData.name ||
                       user.email?.split('@')[0] || 
                       'Anonymous',
          avatar_url: identityData.avatar_url || 
                     identityData.picture || 
                     null,
          created_at: user.created_at,
          last_sign_in: user.last_sign_in_at
        };

        // Check if user already exists to preserve profile data
        const { data: existingUser, error: fetchError } = await supabase
          .from('public_users')
          .select('account_type, preferred_platform')
          .eq('id', user.id)
          .single();

        if (fetchError && fetchError.code !== 'PGRST116') {
          throw fetchError;
        }

        // Preserve existing profile data if it exists
        if (existingUser) {
          userData.account_type = existingUser.account_type;
          userData.preferred_platform = existingUser.preferred_platform;
        }

        console.log('Upserting user data:', userData);

        // Perform the upsert
        const { error: upsertError } = await supabase
          .from('public_users')
          .upsert(userData, {
            onConflict: 'id',
            returning: 'minimal'
          });

        if (upsertError) throw upsertError;

        return userData;
      } catch (error) {
        console.error('Error upserting user:', error);
        throw error;
      }
    };

    // Handle initial session
    const initializeSession = async () => {
      try {
        const { data: { session }, error: sessionError } = await supabase.auth.getSession();
        if (sessionError) throw sessionError;
        
        if (mounted && session?.user) {
          setSession(session);
          
          // Get fresh user data from the server
          const { data: { user }, error: userError } = await supabase.auth.getUser();
          if (userError) throw userError;
          
          // First update user data
          await upsertUser(user);
          
          // Then check profile completion
          const hasCompleteProfile = await checkUserProfile(user.id);
          console.log('Profile completion status:', hasCompleteProfile);

          if (!hasCompleteProfile) {
            navigate('/onboard', { replace: true });
          } else {
            navigate('/ideas', { replace: true });
          }
        }
      } catch (error) {
        console.error('Error initializing session:', error);
        toast.error('Error initializing session. Please try again.');
      } finally {
        if (mounted) {
          setIsLoading(false);
        }
      }
    };

    initializeSession();

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange(
      async (event, session) => {
        console.log('Auth state changed:', event);
        
        if (mounted) {
          setSession(session);
          
          if (event === 'SIGNED_IN' && session?.user) {
            setIsLoading(true);
            try {
              // Get fresh user data from the server
              const { data: { user }, error: userError } = await supabase.auth.getUser();
              if (userError) throw userError;
              
              // First update user data
              await upsertUser(user);
              // Then check profile completion
              const hasCompleteProfile = await checkUserProfile(user.id);

              if (!hasCompleteProfile) {
                navigate('/onboard', { replace: true });
              } else {
                navigate('/ideas', { replace: true });
              }
            } catch (error) {
              console.error('Error handling auth state change:', error);
              toast.error('There was an error processing your login. Please try again.');
            } finally {
              setIsLoading(false);
            }
          } else if (event === 'SIGNED_OUT') {
            navigate('/', { replace: true });
          }
        }
      }
    );

    return () => {
      mounted = false;
      subscription.unsubscribe();
    };
  }, [navigate]);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen bg-gray-100">
        <div className="text-xl">Loading...</div>
      </div>
    );
  }

  return (
    <div className="flex items-start pt-16 justify-center min-h-[44vh] bg-gray-100 p-4">
      <div className="bg-white p-8 rounded-xl shadow-lg w-full max-w-sm">
        {session ? (
          <div className="text-center">
            <h1 className="text-2xl font-bold mb-4">Logged in!</h1>
            <p>Redirecting you...</p>
          </div>
        ) : (
          <>
            <h2 className="text-2xl font-bold text-center mb-2">Welcome to Bounties!</h2>
            <Auth 
              supabaseClient={supabase}
              appearance={{ 
                theme: ThemeSupa,
                style: {
                  button: { marginBottom: '8px' },
                  anchor: { marginBottom: '16px' }
                }
              }}
              providers={['google','discord']}
              view="sign_in"
              showLinks={false}
              onError={(error) => {
                console.error('Auth error:', error);
                toast.error('Authentication error. Please try again.');
              }}
              onlyThirdPartyProviders
            />
          </>
        )}
      </div>
    </div>
);
}