import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { createClient } from "@supabase/supabase-js";
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import toast from 'react-hot-toast';
import { Filter } from 'bad-words';
import { array as badWordsArray } from 'badwords-list';

import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragOverlay,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import { restrictToVerticalAxis } from '@dnd-kit/modifiers';

import {
  defaultAnimateLayoutChanges,
  useSortable,
} from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

const filter = new Filter();
filter.addWords(...badWordsArray);

// SortableLink Component
const SortableLink = ({ id, url, onDelete, onUrlChange }) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({ id });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  };

  return (
    <div 
      ref={setNodeRef} 
      style={style} 
      className="flex items-center gap-2 mb-2 group"
    >
      <div 
        {...attributes} 
        {...listeners}
        className="cursor-grab p-2 hover:bg-gray-100 rounded"
      >
        ⋮⋮
      </div>
      <input
        type="url"
        value={url}
        onChange={(e) => onUrlChange(id, e.target.value)}
        className="flex-1 rounded-lg border-gray-200 p-3 text-sm"
        placeholder="https://link-to-supporting-information"
        autocomplete="one-time-code" 
      />
      <button
        onClick={() => onDelete(id)}
        className="p-2 text-red-500 sm-opacity-0 group-hover:opacity-100 transition-opacity"
        type="button"
      >
        ×
      </button>
    </div>
  );
};

export default function SubmitIdea() {
  const location = useLocation();
  const navigate = useNavigate();
  const [oneLiner, setOneLiner] = useState('');
  const [platform, setPlatform] = useState('');
  const [links, setLinks] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [isDateValid, setIsDateValid] = useState(true);
  const [activeId, setActiveId] = useState(null);
  const [expiration, setExpiration] = useState(new Date(new Date().setDate(new Date().getDate() + 14)));
  const [daysUntilExpiration, setDaysUntilExpiration] = useState(14);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [userPreferences, setUserPreferences] = useState(null);
  const [selectedDurations, setSelectedDurations] = useState([]);

  const durationInfo = {
    SHORT: {
      label: 'Short (< 3 mins)',
      description: 'Best for Shorts, Reels, or TikTok'
    },
    MEDIUM: {
      label: 'Medium (5 - 25 mins)',
      description: 'Best for reviews, gameplay, etc.'
    },
    LONG: {
      label: 'Long (30+ mins)',
      description: 'Best for in-depth content'
    }
  };

  useEffect(() => {
    if (location.state?.idea) {
      setOneLiner(location.state.idea);
    }
  }, [location.state]);

  // Initialize user state
  useEffect(() => {
    const initializeUser = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        if (!user) {
          navigate('/login');
          return;
        }
        setUserUID(user.id);

        // Fetch user preferences
        const { data: preferences, error: preferencesError } = await supabase
          .from('public_users')
          .select('preferred_platform')
          .eq('id', user.id)
          .single();

        if (preferencesError && preferencesError.code !== 'PGRST116') { // PGRST116 is "no rows returned" error
          console.error('Error fetching preferences:', preferencesError);
        } else if (preferences) {
          setUserPreferences(preferences);
          // Set platform from preferences if available
          if (preferences.preferred_platform) {
            setPlatform(preferences.preferred_platform);
          }
        }

      } catch (error) {
        console.error('Error checking user session:', error);
        toast.error('Failed to check login status');
        navigate('/login');
      }
    };

    initializeUser();
  }, [navigate]);

  useEffect(() => {
    if (location.state?.idea) {
      setOneLiner(location.state.idea);
    }
  }, [location.state]);

  const toggleDuration = (durationType) => {
    setSelectedDurations(prev => {
      if (prev.includes(durationType)) {
        return prev.filter(d => d !== durationType);
      } else {
        return [...prev, durationType];
      }
    });
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragStart = (event) => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      setLinks((items) => {
        const oldIndex = items.findIndex((item) => item.id === active.id);
        const newIndex = items.findIndex((item) => item.id === over.id);
        return arrayMove(items, oldIndex, newIndex);
      });
    }
    setActiveId(null);
  };

  const addLink = () => {
    const newLink = {
      id: `link-${Date.now()}`,
      url: ''
    };
    setLinks([...links, newLink]);
  };

  const deleteLink = (id) => {
    setLinks(links.filter(link => link.id !== id));
  };

  const updateLinkUrl = (id, newUrl) => {
    setLinks(links.map(link => 
      link.id === id ? { ...link, url: newUrl } : link
    ));
  };

  const handleDateChange = (date) => {
    setExpiration(date);
    const isValid = date > new Date();
    setIsDateValid(isValid);
    if (isValid) {
      const diffInTime = date.getTime() - new Date().getTime();
      const diffInDays = Math.ceil(diffInTime / (1000 * 3600 * 24));
      setDaysUntilExpiration(diffInDays);
    }
  };

  const RESTRICTED_DOMAINS = [
    'pornhub',
    'xvideos',
    'xhamster',
    'redtube',
    'youporn',
    'onlyfans',
    'xnxx',
    'brazzers',
    'chaturbate',
    'myfreecams',
    'stripchat',
    'cam4',
    'livejasmin',
    'adultfriendfinder',
    'flirt4free',
    'streamate',
  ];
  
  // Common adult content keywords in URLs
  const ADULT_URL_PATTERNS = [
    'porn',
    'xxx',
    'adult',
    'sex',
    'escort',
    'nude',
    'naked',
    'cam2cam',
    'camgirl',
    'nsfw',
  ];
  
  const validateContent = () => {
    // Helper function to clean the text for checking
    const cleanText = (text) => {
      return text.toLowerCase()
        .replace(/0/g, 'o')
        .replace(/1/g, 'i')
        .replace(/3/g, 'e')
        .replace(/4/g, 'a')
        .replace(/5/g, 's')
        .replace(/\$/g, 's')
        .replace(/\*/g, '')
        .replace(/@/g, 'a')
        .replace(/[^\w\s]/g, ''); // Remove special characters
    };
  
    // Helper function to check if URL is from a restricted domain
    const isRestrictedURL = (url) => {
      try {
        const lowercaseUrl = url.toLowerCase();
        
        // Check against restricted domains
        if (RESTRICTED_DOMAINS.some(domain => lowercaseUrl.includes(domain))) {
          console.log('Restricted domain detected');
          return true;
        }
  
        // Check against adult content patterns
        if (ADULT_URL_PATTERNS.some(pattern => lowercaseUrl.includes(pattern))) {
          console.log('Adult content pattern detected in URL');
          return true;
        }
  
        // Check for common adult content TLDs
        if (lowercaseUrl.match(/\.(xxx|adult|porn|sex)$/)) {
          console.log('Adult content TLD detected');
          return true;
        }
  
        // Parse URL to check domain specifically
        const urlObj = new URL(url);
        const domain = urlObj.hostname.toLowerCase();
        
        if (RESTRICTED_DOMAINS.some(restrictedDomain => 
          domain === restrictedDomain || 
          domain.endsWith(`.${restrictedDomain}`) || 
          domain.startsWith(`${restrictedDomain}.`)
        )) {
          console.log('Restricted domain detected in hostname');
          return true;
        }
  
        return false;
      } catch (error) {
        console.error('Error parsing URL:', error);
        return false; // Invalid URLs will be caught by other validation
      }
    };
  
    // Check one-liner
    const cleanedOneLiner = cleanText(oneLiner);
    if (filter.isProfane(oneLiner) || filter.isProfane(cleanedOneLiner)) {
      console.log('Inappropriate content found in one-liner');
      return false;
    }
  
    // Check all links
    for (const link of links) {
      if (link.url) {
        // First check if it's a restricted URL
        if (isRestrictedURL(link.url)) {
          console.log('Adult content site detected');
          return false;
        }
  
        const cleanedUrl = cleanText(link.url);
        
        // Split URL into parts to check each segment
        const urlParts = link.url.split(/[/.-_?&=]/);
        const cleanedUrlParts = urlParts.map(part => cleanText(part));
  
        // Check each part of the URL
        for (const part of cleanedUrlParts) {
          if (filter.isProfane(part)) {
            console.log('Inappropriate content found in link');
            return false;
          }
        }
  
        // Check the full URL
        if (filter.isProfane(cleanedUrl)) {
          console.log('Inappropriate content found in full URL');
          return false;
        }
      }
    }
  
    return true;
  };
  
  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!userUID) {
      toast.error('Please sign in to submit an idea');
      navigate('/login');
      return;
    }
  
    if (!isDateValid) {
      toast.error('Please choose a valid date');
      return;
    }
  
    if (selectedDurations.length === 0) {
      toast.error('Please select at least one video duration');
      return;
    }
  
    if (!platform) {
      toast.error('Please select a platform');
      return;
    }
  
    if (!oneLiner.trim()) {
      toast.error('Please enter your idea');
      return;
    }
  
    if (!validateContent()) {
      toast.error('Proposal contains inappropriate content or restricted URLs and cannot be submitted');
      return;
    }
  
    setIsSubmitting(true);
    
  
    const formattedLinks = links
    .filter(link => link.url.trim())
    .map(link => `- ${link.url.trim()}`)
    .join('\n');

  const description = formattedLinks ? `Supporting Links:\n${formattedLinks}` : '';

  setIsSubmitting(true);

  try {
    const response = await fetch('/server/api/proposals/submit', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        oneLiner: oneLiner.trim(),
        description,  // Send formatted links as description
        platform,
        userId: userUID,
        expiration: expiration.toISOString(),
        selectedDurations
      })
    });
  
      const data = await response.json();
  
      if (!response.ok) {
        throw new Error(data.error || 'Failed to submit proposal');
      }
  
      toast.success('Your idea was submitted successfully!');
      navigate(`/expanded-proposal/${data.proposal.id}`);
  
    } catch (error) {
      console.error('Error submitting idea:', error);
      toast.error(
        error.message === 'Failed to submit proposal'
          ? 'Something went wrong. Please try again.'
          : error.message
      );
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <div>
      <section className="bg-gray-100">
        <div className="mx-auto max-w-screen-xl px-4 py-16 sm:px-6 lg:px-8">
          <div className="grid grid-colzs-1 gap-x-16 gap-y-8 lg:grid-cols-5">
            <div className="lg:col-span-2 lg:py-12">
              <p className="max-w-xl text-lg">
                Submit your original video idea.
              </p>
            </div>
            <div className="rounded-lg bg-white p-8 shadow-lg lg:col-span-3 lg:p-12">
              <form onSubmit={handleSubmit} className="space-y-4">
                {/* One-liner input */}
                <div>
          
                  <label htmlFor="oneLiner" className="sr-only">One-liner describing your idea</label>
                  <input
                    type="text"
                    id="oneLiner"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm"
                    placeholder="A one-liner describing your idea"
                    value={oneLiner}
                    onChange={(e) => setOneLiner(e.target.value)}
                    autocomplete="one-time-code" 
                    autoComplete='off'
                    required
                  />
                </div>

                {/* Platform selection */}
                <p>Which platform should this content be posted on?</p>
                <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
                  {/* Platform radio buttons remain the same */}
                  {['YouTube', 'TikTok', 'Any'].map((platformOption) => (
                    <div key={platformOption.toLowerCase()}>
                      <input
                        type="radio"
                        id={platformOption.toLowerCase()}
                        className="peer sr-only"
                        name="platform"
                        value={platformOption}
                        checked={platform === platformOption}
                        onChange={(e) => setPlatform(e.target.value)}
                        required
                      />
                      <label
                        htmlFor={platformOption.toLowerCase()}
                        className="block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black peer-checked:border-black peer-checked:bg-black peer-checked:text-white"
                        tabIndex="0"
                      >
                        <span className="text-sm">{platformOption}</span>
                      </label>
                    </div>
                  ))}
                </div>
                <p>How long should this video be?</p>
                <div>
  <div>
  
  <div className="grid grid-cols-1 gap-4 text-center sm:grid-cols-3">
    {Object.entries(durationInfo).map(([durationType, info]) => (
      <div key={durationType}>
        <input
          type="checkbox"
          id={`duration-${durationType.toLowerCase()}`}
          className="peer sr-only"
          checked={selectedDurations.includes(durationType)}
          onChange={() => toggleDuration(durationType)}
        />
        <label
          htmlFor={`duration-${durationType.toLowerCase()}`}
          className={`block w-full rounded-lg border border-gray-200 p-3 text-gray-600 hover:border-black 
            peer-checked:border-black peer-checked:bg-black peer-checked:text-white
            cursor-pointer transition-colors duration-200`}
          tabIndex="0"
        >
          <span className="text-sm font-medium">
            {info.label}
          </span>
          <div className={`mt-2 text-xs ${selectedDurations.includes(durationType) ? 'text-gray-200' : 'text-gray-500'}`}>
            {info.description}
          </div>
        </label>
      </div>
    ))}
  </div>
  {selectedDurations.length === 0 && (
    <p className="mt-2 text-xs text-gray-500">
      Select at least one recommended duration.
    </p>
  )}
</div>
                </div> 

                {/* Date picker */}
                <div>
                  <p>Give a deadline for creators to make your idea. </p>
                  <DatePicker
                    selected={expiration}
                    onChange={handleDateChange}
                    dateFormat="Pp"
                    className="w-full rounded-lg border-gray-200 p-3 text-sm mt-2"
                  />
                  {!isDateValid ? (
                    <p className="text-red-500 text-sm font-semibold mt-2">Choose a valid date.</p>
                  ) : (
                    <p className="text-gray-400 text-sm font-semibold mt-2">
                      Creators have <span className='text-red-600'> {daysUntilExpiration} {daysUntilExpiration === 1 ? 'day' : 'days'}</span> to make your idea.
                    </p>
                  )}
                </div>

                {/* Links section */}
                <div>
                  <p className="mb-2">Add links to helpful resources about your idea (optional):</p>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragStart={handleDragStart}
                    onDragEnd={handleDragEnd}
                    modifiers={[restrictToVerticalAxis]}
                  >
                    <SortableContext
                      items={links.map(link => link.id)}
                      strategy={verticalListSortingStrategy}
                    >
                      {links.map((link) => (
                        <SortableLink
                          key={link.id}
                          id={link.id}
                          url={link.url}
                          onDelete={deleteLink}
                          onUrlChange={updateLinkUrl}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>
                  
                  <button
                    type="button"
                    onClick={addLink}
                    className="mt-2 text-sm text-gray-600 hover:text-black underline"
                  >
                    + Add Link
                  </button>
                </div>

                {/* Submit button */}
                <div className="mt-4">
                  <button
                    type="submit"
                    disabled={isSubmitting}
                    className={`inline-block w-full rounded-lg bg-black px-5 py-3 font-medium text-white sm:w-auto ${
                      isSubmitting ? 'opacity-50 cursor-not-allowed' : ''
                    }`}
                  >
                    {isSubmitting ? 'Submitting...' : 'Submit Idea!'}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}