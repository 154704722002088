import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import { 
  differenceInDays, 
  differenceInHours, 
  differenceInMinutes, 
  isPast 
} from 'date-fns';
import ContributeButton from './ContributeButton';
import StripeCheckout from './StripeCheckout';
import toast from 'react-hot-toast';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Proposal({ initialParam = "recent", searchQuery = '', emptyStateMessage }) {
  const [proposals, setProposals] = useState([]);
  const [userUID, setUserUID] = useState(null);
  const [param, setParam] = useState(initialParam);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const query = searchParams.get('q') || searchQuery;

  const paramLabels = {
    recent: 'Recent',
    popular: 'Popular',
    payout: 'Payout',
    expiring: 'Expiring Soon'
  };

  const isDiscoverPage = location.pathname === '/discover';
  const filteredParamLabels = isDiscoverPage 
    ? Object.fromEntries(Object.entries(paramLabels).filter(([key]) => key !== 'expiring')) 
    : paramLabels;

  // Initialize user state
  useEffect(() => {
    const initializeUser = async () => {
      try {
        const { data: { user }, error } = await supabase.auth.getUser();
        if (error) throw error;
        setUserUID(user?.id || null);
      } catch (error) {
        console.error('Error initializing user:', error);
        setUserUID(null);
      }
    };

    initializeUser();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setUserUID(session?.user?.id || null);
    });

    return () => subscription.unsubscribe();
  }, []);

  // Fetch proposals when params change
  useEffect(() => {
    const fetchProposals = async () => {
      setLoading(true);
      try {
        const response = await fetch(
          `/server/api/proposals?param=${param}&query=${query}&path=${location.pathname}${userUID ? `&userId=${userUID}` : ''}`
        );
        
        if (!response.ok) {
          throw new Error('Failed to fetch proposals');
        }

        const data = await response.json();
        setProposals(data);
      } catch (error) {
        console.error('Error fetching proposals:', error);
        toast.error('Failed to load proposals');
      } finally {
        setLoading(false);
      }
    };

    fetchProposals();
  }, [param, userUID, query, location.pathname]);

  const handleContributeClick = async (id, amount) => {
    try {
      if (!userUID) {
        toast.error('Please sign in to contribute');
        navigate('/login');
        return;
      }

      const response = await fetch('/server/api/stripe/create-checkout', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          postId: id,
          amount: Math.round(amount * 100),
          type: 'proposal',
          userId: userUID
        }),
      });

      if (!response.ok) {
        const error = await response.json();
        throw new Error(error.message || 'Failed to create checkout session');
      }

      const { url } = await response.json();
      if (url) {
        toast.promise(
          Promise.resolve(window.location.href = url),
          {
            loading: 'Preparing checkout...',
            success: 'Redirecting to secure payment',
            error: 'Could not initialize payment'
          }
        );
      }
    } catch (error) {
      console.error('Payment initiation error:', error);
      toast.error(`Payment failed: ${error.message}`);
    }
  };

  const handleVote = async (proposalId, vote) => {
    if (!userUID) {
      toast.error('Please sign in to vote');
      return;
    }

    try {
      // Optimistic update
      const currentProposal = proposals.find(p => p.id === proposalId);
      const existingVote = currentProposal.userVote || 0;
      const newVote = existingVote === vote ? 0 : vote;
      const voteDifference = newVote - existingVote;

      // Update local state first
      setProposals(prevProposals => 
        prevProposals.map(proposal => {
          if (proposal.id === proposalId) {
            return {
              ...proposal,
              votes: proposal.votes + voteDifference,
              userVote: newVote
            };
          }
          return proposal;
        })
      );

      // Send vote to server
      const response = await fetch('/server/api/proposals/vote', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          proposalId,
          vote: newVote,
          userId: userUID
        })
      });

      if (!response.ok) {
        throw new Error('Failed to update vote');
      }

    } catch (error) {
      console.error('Error handling vote:', error);
      toast.error('Failed to update vote');
      // Revert local state by refetching proposals
      const response = await fetch(
        `/server/api/proposals?param=${param}&query=${query}&path=${location.pathname}&userId=${userUID}`
      );
      const data = await response.json();
      setProposals(data);
    }
  };

  const getTimeRemaining = (expirationDate) => {
    const now = new Date();
    const expiration = new Date(expirationDate);
    
    if (isPast(expiration)) {
      return 'Completed';
    }

    const daysRemaining = differenceInDays(expiration, now);
    if (daysRemaining > 0) {
      return `${daysRemaining} ${daysRemaining === 1 ? 'day' : 'days'} left`;
    }

    const hoursRemaining = differenceInHours(expiration, now);
    if (hoursRemaining > 0) {
      return `${hoursRemaining} ${hoursRemaining === 1 ? 'hour' : 'hours'} left`;
    }

    const minutesRemaining = differenceInMinutes(expiration, now);
    return `${minutesRemaining} ${minutesRemaining === 1 ? 'minute' : 'minutes'} left`;
  };

  
  return (
    <>
      <div className="relative mb-4" style={{ zIndex: 20 }}>
        <div className="inline-flex items-center overflow-hidden rounded-md border bg-white">
          <button
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            className="px-4 py-2 text-sm/none text-gray-600 hover:bg-gray-50 hover:text-gray-700"
          >
            {paramLabels[param]}
          </button>

          <button 
            className="h-full p-2 text-gray-600 hover:bg-gray-50 hover:text-gray-700"
            onClick={() => setIsDropdownOpen(!isDropdownOpen)}
          >
            <span className="sr-only">Menu</span>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-4 w-4"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </button>
        </div>

        {isDropdownOpen && (
          <div
            className="absolute left-0 right-0 z-10 mt-2 w-56 rounded-md border border-gray-100 bg-white shadow-lg"
            role="menu"
          >
            <div className="p-2">
              {Object.entries(filteredParamLabels).map(([value, label]) => (
                <button
                  key={value}
                  onClick={() => {
                    setParam(value);
                    setIsDropdownOpen(false);
                  }}
                  className="block w-full text-left rounded-lg px-4 py-2 text-sm text-gray-500 hover:bg-gray-50 hover:text-gray-700"
                  role="menuitem"
                >
                  {label}
                </button>
              ))}
            </div>
          </div>
        )}
      </div>

      {proposals.length === 0 ? (
        emptyStateMessage || (
          <div className="text-center mt-16" style={{ zIndex: 10 }}>
            <p className="text-3xl text-gray-500">Be the first to submit!</p>
            <p className="text-gray-500 sm:text-xl">Early submissions are more likely to be rewarded.</p>
          </div>
        )
      ) : (
        proposals.map((proposal) => (
          <article key={proposal.id} style={{ zIndex: 10 }} className="rounded-xl border-2 border-gray-100 bg-white mb-8">
            <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
              <div className="flex-1 min-w-0 pr-4">
                <h3 className="font-medium sm:text-lg">
                  <Link to={`/expanded-proposal/${proposal.id}`}>
                    {proposal.title}
                  </Link>
                </h3>
                
                <p 
                  className="line-clamp-2 text-sm text-gray-700 mb-4 overflow-hidden max-w-full break-words"
                  style={{ 
                    whiteSpace: 'pre-wrap',
                    overflowWrap: 'break-word',
                    wordWrap: 'break-word'
                  }}
                >
                  {proposal.description === "" ?  "No description provided." : proposal.description}
                </p>

                <div className="flex items-center gap-2 mb-2">
  <span className="text-sm font-semibold">Current Bounty</span>
  <div className="flex items-center rounded-md min-w-[200px] h-[30px] bg-gray-100 overflow-hidden relative">
    {/* Progress bar background with dynamic goal calculation */}
    <div 
      className="absolute inset-0 transition-all duration-500 ease-in-out"
      style={{ 
        width: (() => {
          const currentAmount = proposal.bountyAmount || 0;
          // Find the next power of 10
          const currentOrder = Math.floor(Math.log10(currentAmount || 1));
          const nextGoal = Math.pow(10, currentOrder + 1);
          // Calculate progress percentage towards next goal
          const progressPercentage = (currentAmount / nextGoal) * 100;
          return `${progressPercentage}%`;
        })(),
        background: 'linear-gradient(to right, #22c55e, #22c55e 95%)'
      }}
    />
    
    {/* Content overlay - moved outside of progress bar */}
    <div className="relative flex justify-between items-center w-full px-2">
      <span className="text-sm font-bold z-10 text-black">
        ${(proposal.bountyAmount || 0).toFixed(2)}
      </span>
      <button 
        onClick={() => handleContributeClick(proposal.id, 5.00)}
        className="text-gray-600 hover:text-gray-900 z-10 disabled:opacity-50"
      >
        <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
          <path fillRule="evenodd" d="M10 5a1 1 0 011 1v3h3a1 1 0 110 2h-3v3a1 1 0 11-2 0v-3H6a1 1 0 110-2h3V6a1 1 0 011-1z" clipRule="evenodd" />
        </svg>
      </button>
    </div>
    
    {/* Dynamic target amount label */}
    <span className="absolute right-0 -bottom-5 text-xs text-gray-500">
      ${(() => {
        const currentAmount = proposal.bountyAmount || 0;
        const currentOrder = Math.floor(Math.log10(currentAmount || 1));
        return Math.pow(10, currentOrder + 1).toLocaleString();
      })()}
    </span>
  </div>
</div>
            

                <div className="mt-2 sm:flex sm:items-center sm:gap-2">
                  <div className="flex items-center gap-1 text-gray-500">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-4 w-4"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                      strokeWidth="2"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        d="M17 8h2a2 2 0 012 2v6a2 2 0 01-2 2h-2v4l-4-4H9a1.994 1.994 0 01-1.414-.586m0 0L11 14h4a2 2 0 002-2V6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2v4l.586-.586z"
                      />
                    </svg>

                    <p className="text-xs font-medium">
                      {proposal.submissionCount} {proposal.submissionCount === 1 ? 'submission' : 'submissions'}
                    </p>
                  </div>

                  <span className="hidden sm:block" aria-hidden="true">&middot;</span>

                  <p className="hidden sm:block sm:text-xs sm:text-gray-500 mb-0 pb-0">
                    <span className="font-medium">{getTimeRemaining(proposal.expiration)}</span>
                  </p>
                </div>
              </div>

              <div className="flex flex-col items-center">
                <button
                  className={`transition-transform duration-200 ease-in-out ${
                    userUID 
                      ? proposal.userVote === 1 
                        ? 'text-green-500' 
                        : proposal.userVote === 0
                          ? 'text-gray-500 hover:text-green-500'
                          : 'text-gray-500 hover:text-green-500'
                      : 'text-gray-400 cursor-not-allowed'
                  }`}
                  aria-label="Upvote"
                  onClick={() => userUID && handleVote(proposal.id, 1)}
                  disabled={!userUID}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
                  </svg>
                </button>
                <span className="text-4xl font-bold transform scale-50">
                  {proposal.votes}
                </span>
                <button
                  className={`transition-transform duration-200 ease-in-out ${
                    userUID 
                      ? proposal.userVote === -1 
                        ? 'text-red-500' 
                        : proposal.userVote === 0
                          ? 'text-gray-500 hover:text-red-500'
                          : 'text-gray-500 hover:text-red-500'
                      : 'text-gray-400 cursor-not-allowed'
                  }`}
                  aria-label="Downvote"
                  onClick={() => userUID && handleVote(proposal.id, -1)}
                  disabled={!userUID}
                >
                  <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
                  </svg>
                </button>
              </div>
            </div>
          </article>
        ))
      )}
    </>
  );
}