import React from 'react';
import { Link } from 'react-router-dom';

export default function Submission({ 
  submission, 
  userUID, 
  onVote,
  className = '' 
}) {
  const handleVote = async (vote) => {
    if (!userUID) {
      console.log('Cannot submit vote, not signed in');
      alert('You must be logged in to vote.');
      return;
    }
    
    onVote(submission.id, vote);
  };

  return (
    <section className={`mb-0 pb-0 ${className}`}>
      <article className="rounded-xl border-2 border-gray-100 bg-white mt-4">
        <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
          <div className="flex-1 min-w-0 pr-4">
            <h3 className="font-medium sm:text-lg">
              <a 
                href={submission.link} 
                target="_blank" 
                rel="noopener noreferrer"
                className="hover:text-blue-600"
              >
                {submission.title}
              </a>
            </h3>
    
            <p 
              className="line-clamp-2 text-sm text-gray-700 mb-4 overflow-hidden max-w-full break-words"
              style={{ 
                whiteSpace: 'pre-wrap',
                overflowWrap: 'break-word',
                wordWrap: 'break-word'
              }}
            >
              <a 
                href={submission.link} 
                target="_blank" 
                rel="noopener noreferrer"
                className="text-gray-600 hover:text-blue-600 hover:underline"
              >
                {submission.link || 'No link provided'}
              </a>
            </p>
    
            <div className="mt-2 sm:flex sm:items-center sm:gap-2">
              <div className="flex items-center gap-1 text-gray-500">
                <p className="text-xs font-medium">
                  Posted by <span className="font-medium">{submission.authorDisplayName}</span>
                </p>
              </div>
    
              {submission.platform && (
                <>
                  <span className="hidden sm:block" aria-hidden="true">&middot;</span>
                  <p className="hidden sm:block sm:text-xs sm:text-gray-500">
                    <span className="font-medium">{submission.platform}</span>
                  </p>
                </>
              )}
            </div>
          </div>

          <div className="flex flex-col items-center">
            <button
              className={`transition-transform duration-200 ease-in-out ${
                userUID 
                  ? submission.userVote === 1 
                    ? 'text-green-500' 
                    : submission.userVote === 0
                      ? 'text-gray-500 hover:text-green-500'
                      : 'text-gray-500 hover:text-green-500'
                  : 'text-gray-400 cursor-not-allowed'
              }`}
              aria-label="Upvote"
              onClick={() => handleVote(1)}
              disabled={!userUID}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 15l7-7 7 7" />
              </svg>
            </button>
            <span className="text-4xl font-bold transform scale-50">
              {submission.votes || 0}
            </span>
            <button
              className={`transition-transform duration-200 ease-in-out ${
                userUID 
                  ? submission.userVote === -1 
                    ? 'text-red-500' 
                    : submission.userVote === 0
                      ? 'text-gray-500 hover:text-red-500'
                      : 'text-gray-500 hover:text-red-500'
                  : 'text-gray-400 cursor-not-allowed'
              }`}
              aria-label="Downvote"
              onClick={() => handleVote(-1)}
              disabled={!userUID}
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="h-4 w-4" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M19 9l-7 7-7-7" />
              </svg>
            </button>
          </div>
        </div>
      </article>
    </section>
  );
}