import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import { createClient } from '@supabase/supabase-js';
import axios from 'axios';
import { toast } from 'react-hot-toast';

const supabase = createClient(process.env.REACT_APP_SUPABASE_PROJECT_URL, process.env.REACT_APP_SUPABASE_KEY);

export default function Profile() {
  const [videos, setVideos] = useState([]);
  const [displayName, setDisplayName] = useState('');
  const [user, setUser] = useState({ 
    account_type: '', 
    preferred_platform: '',
    platforms: {
      tiktok: { connected: false, last_sync: null },
      youtube: { connected: false, last_sync: null }
    }
  });
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const [submissions, setSubmissions] = useState([]);
  const [proposals, setProposals] = useState([]);
  const [selectedTab, setSelectedTab] = useState('bounties');
  const [isProcessingTikTok, setIsProcessingTikTok] = useState(false);
  const [proposalContributions, setProposalContributions] = useState({});
  const [userTotalContributions, setUserTotalContributions] = useState(0);
  const location = useLocation();

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  // Format date helper function
  const formatDate = (dateString) => {
    if (!dateString) return null;
    return new Date(dateString).toLocaleDateString('en-US', {
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const fetchUserData = async (userId) => {
    try {
      const response = await fetch(`/server/api/profile/user?userId=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch user data');
      
      const userData = await response.json();
      setDisplayName(userData.display_name);
      setUser({
        account_type: userData.account_type,
        preferred_platform: userData.preferred_platform,
        platforms: userData.platforms
      });
    } catch (error) {
      console.error('Error fetching user data:', error);
      toast.error('Failed to load user data');
    }
  };

  const fetchProposals = async (userId) => {
    try {
      const response = await fetch(`/server/api/profile/proposals?userId=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch proposals');
      
      const proposalsData = await response.json();
      setProposals(proposalsData);
      return proposalsData;
    } catch (error) {
      console.error('Error fetching proposals:', error);
      toast.error('Failed to load proposals');
      return [];
    }
  };

  const fetchSubmissions = async (userId) => {
    try {
      const response = await fetch(`/server/api/profile/submissions?userId=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch submissions');
      
      const submissionsData = await response.json();
      setSubmissions(submissionsData);
    } catch (error) {
      console.error('Error fetching submissions:', error);
      toast.error('Failed to load submissions');
    }
  };

  const fetchVideos = async (userId) => {
    try {
      const response = await fetch(`/server/api/profile/videos?userId=${userId}`);
      if (!response.ok) throw new Error('Failed to fetch videos');
      
      const videosData = await response.json();
      setVideos(videosData);
    } catch (error) {
      console.error('Error fetching videos:', error);
      toast.error('Failed to load videos');
    }
  };

  const fetchContributions = async (userId, proposalIds) => {
    try {
      const response = await fetch(
        `/server/api/profile/contributions?userId=${userId}&proposalIds=${proposalIds.join(',')}`
      );
      if (!response.ok) throw new Error('Failed to fetch contributions');
      
      const data = await response.json();
      setProposalContributions(data.contributions);
      setUserTotalContributions(data.userTotal);
    } catch (error) {
      console.error('Error fetching contributions:', error);
      toast.error('Failed to load contributions');
    }
  };
  
  const request_youtube_token = async () => {
    try {
      const response = await fetch('/server/youtube/oauth', {
        credentials: 'include',
        headers: {
          'Accept': 'application/json',
          'Cache-Control': 'no-cache'
        }
      });
  
      if (!response.ok) {
        throw new Error('Failed to start YouTube authorization');
      }
  
      const data = await response.json();
      if (data?.url) {
        localStorage.setItem('returnToSettings', 'true');
        window.location.href = data.url;
      } else {
        throw new Error('No authorization URL received');
      }
    } catch (error) {
      console.error('Error starting YouTube authorization:', error);
      setError('Failed to start YouTube authorization');
      toast.error('Failed to start YouTube authorization');
    }
  };
  
    
  useEffect(() => {
    const checkUserSession = async () => {
      try {
        const { data: { session }, error } = await supabase.auth.getSession();
    
        if (error) {
          console.error('Error getting session:', error.message);
          return;
        }
    
        if (!session) {
          navigate('/login');
          return;
        }
  
        const userUID = session.user.id;
  
        // Initial data fetching
        const proposalsData = await fetchProposals(userUID);
        await Promise.all([
          fetchUserData(userUID),
          fetchSubmissions(userUID),
          fetchVideos(userUID)
        ]);
  
        // After proposals are fetched, get contributions
        if (proposalsData?.length > 0) {
          await fetchContributions(userUID, proposalsData.map(p => p.id));
        }
  
      } catch (error) {
        console.error('Error in checkUserSession:', error);
        toast.error('Failed to load user data');
      }
    };
  
    checkUserSession();
  }, [navigate]);  

  const request_token = async () => {
    try {
      const response = await axios.get(
        'https://bountiesapp.com/server/oauth',
        { 
          withCredentials: true,
          headers: {
            'Accept': 'application/json',
            'Cache-Control': 'no-cache'
          }
        }
      );

      if (response.data?.url) {
        console.log("Redirecting to TikTok auth URL:", response.data.url);
        localStorage.setItem('returnToSettings', 'true');
        window.location.href = response.data.url;
      } else {
        throw new Error("No authorization URL received");
      }
    } catch (error) {
      console.error("Error starting TikTok authorization:", error);
      setError("Failed to start TikTok authorization: " + (error.response?.data?.error || error.message));
    }
  };

  const handleTikTokCallback = async (code, state) => {
    if (isProcessingTikTok) return;
    setIsProcessingTikTok(true);
  
    try {
      // Get TikTok videos
      const response = await fetch('/server/tiktokaccesstoken', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ code, state })
      });
  
      if (!response.ok) {
        throw new Error('Failed to get TikTok videos');
      }
  
      const videos = await response.json();
      if (!Array.isArray(videos)) {
        throw new Error('Invalid response format');
      }
  
      // Get current user
      const { data: { session } } = await supabase.auth.getSession();
      if (!session?.user.id) {
        throw new Error('No user session');
      }
  
      // Sync videos
      const syncResponse = await fetch('/server/api/profile/sync-tiktok', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId: session.user.id,
          videos: videos
        })
      });
  
      if (!syncResponse.ok) {
        throw new Error('Failed to sync videos');
      }
  
      await fetchVideos(session.user.id);
      toast.success('TikTok account connected successfully');
      setError(null);
  
    } catch (error) {
      console.error('Error processing TikTok callback:', error);
      toast.error('Failed to connect TikTok account');
    } finally {
      setIsProcessingTikTok(false);
    }
  };
  

  const initializeGoogleAPI = () => {
    window.gapi.load('client:auth2', () => {
      window.gapi.auth2.init({ client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID })
        .then(() => {
          console.log("Google API client initialized");
        })
        .catch(err => {
          console.error("Error initializing Google API client:", err);
        });
    });
  };

  const authenticate = () => {
    return window.gapi.auth2.getAuthInstance()
      .signIn({ scope: "https://www.googleapis.com/auth/youtube.readonly" })
      .then(() => {
        console.log("Sign-in successful");
      })
      .catch(err => {
        console.error("Error signing in:", err);
      });
  };

  const loadClient = () => {
    window.gapi.client.setApiKey(process.env.REACT_APP_YOUTUBE_API_KEY);
    return window.gapi.client.load("https://www.googleapis.com/discovery/v1/apis/youtube/v3/rest")
      .then(() => {
        console.log("GAPI client loaded for API");
      })
      .catch(err => {
        console.error("Error loading GAPI client for API:", err);
      });
  };

  const executeYouTube = async (userId) => {
    try {
      const response = await window.gapi.client.youtube.channels.list({
        "part": ["contentDetails"],
        "mine": true
      });
  
      const channelId = response.result.items[0].id;
  
      const playlistResponse = await window.gapi.client.youtube.playlists.list({
        "part": ["contentDetails"],
        "channelId": channelId,
        "mine": true
      });
  
      const playlistId = playlistResponse.result.items[0].id;
  
      const videosResponse = await window.gapi.client.youtube.playlistItems.list({
        "part": ["snippet"],
        "playlistId": playlistId
      });
  
      const videos = videosResponse.result.items.map(item => ({
        youtube: item.snippet.resourceId.videoId,
        title: item.snippet.title || '',
        description: item.snippet.description || ''
      }));
  
      // Sync videos
      const syncResponse = await fetch('/server/api/profile/sync-youtube', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          videos
        })
      });
  
      if (!syncResponse.ok) {
        throw new Error('Failed to sync videos');
      }
  
      await fetchVideos(userId);
      toast.success('YouTube videos synced successfully');
  
    } catch (error) {
      console.error('Execute error:', error);
      toast.error('Failed to fetch YouTube videos');
    }
  };
  
  const handleAuthorizeAndFetchYouTube = async () => {
    try {
      await authenticate();
      await loadClient();

      const session = await supabase.auth.getSession();
      const userId = session.data.session?.user.id;

      if (userId) {
        await executeYouTube(userId);
      }
    } catch (error) {
      console.error("Error during YouTube authorization and fetching:", error);
      setError("Failed to connect YouTube account: " + error.message);
    }
  };

  const getSubmissionCount = async (proposalId) => {
    try {
      const response = await fetch(`/server/api/proposals/submission-count/${proposalId}`);
      if (!response.ok) {
        throw new Error('Failed to get submission count');
      }
      const data = await response.json();
      return data.count;
    } catch (error) {
      console.error('Error getting submission count:', error);
      return 0;
    }
  };

  useEffect(() => {
    if (location.state?.tiktokAuth) {
      if (location.state.tiktokAuth === 'success') {
        setError(null);
        toast.success(location.state.message || 'TikTok connected successfully');
      } else if (location.state.tiktokAuth === 'error') {
        setError(location.state.error);
        toast.error(location.state.error || 'Failed to connect TikTok');
      }
      window.history.replaceState({}, document.title);
    }

    if (location.state?.youtubeAuth) {
      if (location.state.youtubeAuth === 'success') {
        setError(null);
        toast.success(location.state.message || 'YouTube connected successfully');
      } else if (location.state.youtubeAuth === 'error') {
        setError(location.state.error);
        toast.error(location.state.error || 'Failed to connect YouTube');
      }
      window.history.replaceState({}, document.title);
    }
  }, [location.state]);

  const handleSignOut = async () => {
    try {
      const { error } = await supabase.auth.signOut();
      if (error) throw error;
      navigate('/ideas');
    } catch (error) {
      console.error('Error signing out:', error);
      toast.error('Failed to sign out');
    }
  };

  const handleEdit = () => {
    navigate('/onboard');
  };

  return (
    
    <div>
      <section>
        
        <div className="mx-auto max-w-screen-xl px-4 py-8 sm:py-12 sm:px-6 lg:py-16 lg:px-8">
          
          {/* {displayName} */}
          <h1 className="text-3xl font-bold text-gray-800 mb-2">Welcome, {displayName ? displayName : "Anonymous"}!</h1>

        <div className="mt-4">
        <div className="border-b border-gray-200">
              <nav className="-mb-px flex gap-6" aria-label="Tabs">
                <a
                  href="#"
                  onClick={() => handleTabClick('bounties')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'bounties' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  My Bounties
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('submissions')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'submissions' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Submissions
                </a>

                <a
                  href="#"
                  onClick={() => handleTabClick('settings')}
                  className={`shrink-0 border-b-2 px-1 pb-4 text-sm font-medium ${selectedTab === 'settings' ? 'border-sky-500 text-sky-600' : 'border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700'}`}
                >
                  Settings
                </a>
              </nav>
            </div>
          </div>

          {/* Conditional Rendering based on Selected Tab */}
          {selectedTab === 'bounties' && (
            <>
              {proposals.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your original ideas will appear here.</p>
              ) : (
                proposals.map((proposal) => (
                  <article key={proposal.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${proposal.id}`}>
                            {proposal.title}
                          </Link>
                        </p>
                        <p className="text-sm">Number of Submissions: {proposal.submissionCount}</p>
                        <p className="text-sm">Votes: {proposal.votes || 0}</p>
                        <p className="text-sm">
                          You Contributed: ${((proposalContributions[proposal.id]?.user) || 0).toFixed(2)}
                        </p>
                        <p className="text-sm">
                          Total Contributed: ${((proposalContributions[proposal.id]?.total) || 0).toFixed(2)}
                        </p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}


          {selectedTab === 'submissions' && (
            <>
              {submissions.length === 0 ? (
                <p className="text-gray-500 mt-2 mb-2">Your videos will appear here.</p>
              ) : (
                submissions.map((submission) => (
                  <article key={submission.id} className="rounded-xl border-2 border-gray-100 bg-white mt-2 mb-2 max-w-lg">
                    <div className="flex items-start justify-between p-4 sm:p-6 lg:p-8">
                      <div className="flex-1">
                        <p className="font-medium">
                          <Link to={`/expanded-proposal/${submission.proposal_id}`}>
                            {submission.title}
                          </Link>
                        </p>
                        <p className="text-sm">Video Link: <Link to={submission.link}>{submission.link}</Link></p>
                        <p className="text-sm">Votes: {submission.votes}</p>
                        <p className="text-sm">Expected Payout: $0</p>
                        <p className="text-sm">Max Payout: $0</p>
                      </div>
                    </div>
                  </article>
                ))
              )}
            </>
          )}

{selectedTab === 'settings' && (
  <>
    {/* First section: User Preferences */}
    <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Preferences</h1>
    <p>Display Name: {displayName ? displayName : "Anonymous"}</p>
    <p>Account Type: {user.account_type ? user.account_type : "Viewer"}</p>
    <p>Preferred Platform: {user.preferred_platform || "YouTube, TikTok"}</p>
    <button onClick={handleEdit} className="mt-2 mb-4 inline-block rounded bg-teal-600 px-4 py-2 text-sm font-medium text-white hover:bg-teal-700 focus:outline-none focus:ring">
      Manage Preferences
    </button>

    {/* Second section: Creator Settings */}
    <h1 className="text-2xl font-bold text-gray-800 mt-2 mb-2">Creator Settings</h1>
    <div>
      <h2 className="text-l text-gray-800 mb-4">Link your accounts to post your videos on Bounties.</h2>
      
      {/* TikTok connection success message */}
      {location.state?.tiktokAuth === 'success' && (
        <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
          {location.state.message}
        </div>
      )}

      {/* Connection Buttons and Status Section */}
      <div className="flex flex-col gap-4 mb-4">
        {/* YouTube Connection Container */}
        <div className="flex flex-col items-start">
          <button
            onClick={request_youtube_token}
            className="authorize-button rounded border border-red-600 hover:bg-red-600 px-4 py-2 text-sm font-medium active:bg-transparent text-red-600 hover:text-white focus:outline-none focus:ring active:text-red-500"
          >
            Connect YouTube Channel
          </button>
          {user.platforms.youtube.connected && (
            <p className="text-sm text-gray-600 mt-1">
              Last synced: {formatDate(user.platforms.youtube.last_sync)}
            </p>
          )}
        </div>

        {/* TikTok Connection Container */}
        <div className="flex flex-col items-start">
          <button 
            onClick={request_token} 
            className="rounded border border-slate-900 px-4 py-2 text-sm font-medium text-gray-600 hover:text-white hover:bg-slate-900 focus:outline-none focus:ring active:text-red-500"
            disabled={isProcessingTikTok}
          >
            {isProcessingTikTok ? 'Connecting...' : 'Connect TikTok Account'}
          </button>
          {user.platforms.tiktok.connected && (
            <p className="text-sm text-gray-600 mt-1">
              Last synced: {formatDate(user.platforms.tiktok.last_sync)}
            </p>
          )}
        </div>
      </div>
      {/* Video List Section - Conditional Rendering */}
      {videos && videos.length > 0 ? (
        <div className="mb-4">
          <h3 className="text-md font-semibold mb-2">Your Videos:</h3>
          <ul className="space-y-2">
            {videos.map((video, index) => (
              <li key={`video-${index}`} className="flex items-center p-2 border rounded hover:bg-gray-50">
                <span className={`
                  inline-flex items-center px-2 py-1 text-xs font-semibold rounded mr-2 
                  ${video.type === 'youtube' ? 'bg-red-100 text-red-800' : 'bg-black text-white'}
                `}>
                  {video.type.toUpperCase()}
                </span>
                <div className="flex-1 min-w-0"> {/* min-w-0 ensures proper truncation */}
                  <a 
                    href={video.url}
                    target="_blank" 
                    rel="noopener noreferrer" 
                    className="text-blue-600 hover:underline block font-medium"
                  >
                    {/* Truncate title if longer than 60 characters */}
                    {video.title ? 
                      (video.title.length > 60 ? 
                        `${video.title.substring(0, 60)}...` : 
                        video.title) : 
                      'Untitled Video'
                    }
                  </a>
                  {video.description && (
                    <p className="text-sm text-gray-600 truncate max-w-full">
                      {/* Truncate description to 100 characters */}
                      {video.description.length > 100 ? 
                        `${video.description.substring(0, 100)}...` : 
                        video.description
                      }
                    </p>
                  )}
                </div>
                {video.type === 'youtube' && video.thumbnail && (
                  <div className="flex-shrink-0 ml-2">
                    <img 
                      src={video.thumbnail} 
                      alt={video.title} 
                      className="w-20 h-14 object-cover rounded"
                      loading="lazy"
                    />
                  </div>
                )}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p className="text-gray-500 mb-4">No videos connected.</p>
      )}

      {/* Sign Out Button */}
      <button 
        onClick={handleSignOut} 
        className="mt-4 signout-button inline-block rounded border border-red-600 bg-red-600 px-4 py-2 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring active:text-red-500"
      >
        Sign Out
      </button>
    </div>

    {/* Additional TikTok success message outside the main settings div */}
    {location.state?.tiktokAuth === 'success' && (
      <div className="bg-green-100 border border-green-400 text-green-700 px-4 py-3 rounded mb-4">
        {location.state.message}
      </div>
    )}
  </>
)}
        </div>
      </section>
    </div>
  );
}